/** @jsx jsx */

import { jsx } from 'theme-ui'

import UniversalLink from '../components/universal-link'
import routes from '../constants/routes'

export const halo = {
  title: `Page not found`,
}

export const intro = {
  ...halo,
  description: `The page you're looking for has been removed or relocated.`,
}

export const content = (
  <div sx={{ maxWidth: `var(--maxContentWidth)`, mx: `auto`, py: 5, px: 4, fontSize: 3 }}>
    Go to <UniversalLink to={routes.home}>the CartaX home page</UniversalLink>.
  </div>
)
